export const AppConfig = {
    deepLinkPrefix: '',
    environment: 'WEB',
    smartApprovalEnabled: true,
    production: false,
    pageNode: 'hc_qa',
    PCRValidity: 3, //Number of days before the pcr test expires
    trav_public_endpoint: '',
    localTravEndpoint: 'https://hcqa.tvalidator.app/',
    healthCredentialBaseUrl: 'https://qa-api-hc.travizory.ch/',
    flavor: 'VMP',
    refreshContentInterval: 300000,
    dismissLoginAttemptsInterval: 30000,
    updateStreamInterval: 1000,
    flavorProtocol: '',
    googleMapsKey: '',
    cardsFilterChips: {
        SUBMISSION_RECENT: true,
        ARRIVALS_TOMORROW: false,
        ARRIVALS_FUTURE: false,
        ARRIVALS_TODAY: false,
        isGridView: true,
        UPCOMING_FLIGHTS: true,
        RECENT_PAST_FLIGHTS: false,
        EXCLUDE_ARRIVAL_TODAY: false,
        EXCLUDE_DEPARTURE_TODAY: false,
        RECENT_PAST_VESSELS: false,
        UPCOMING_VESSELS: true,
        EXCLUDE_WORKERS: false,
    },
    establishmentsFilters: {},
    streamFilters: {
        TARECEIVED: true,
        DECISION: true,
        ENCOUNTER: true,
        TAACCESSED: true,
    },
    permitCountries: ['HCQA'],
    userWithVMPAccess: [
        'admin+',
        'agent+',
        'agent++',
        'super_admin',
        'support',
        'support+',
        'viewer',
        'viewer+',
        'agent',
        'admin',
        'establishment_admin',
    ],
    decisionTypes: [],
    initializeMapCoordinates: {},
    establishmentUsersRole: [
        { roleKey: 'establishment_admin', roleName: 'Establishment Admin' },
        { roleKey: 'establishment_user', roleName: 'Establishment User' },
    ],
    userRoles: [
        { key: 'admin', label: 'user_roles.admin' },
        { key: 'agent', label: 'user_roles.agent' },
        { key: 'super_admin', label: 'user_roles.super_admin' },
    ],
    establishmentsType: [],
    countriesTypes: [],
    establishmentsFilterChips: [],
    questionCategory: [],
    questionType: [],
    magicTokenBaseUrl: {},
    defaultCountryCode: 'SYC',
    firebaseConfig: {
        apiKey: 'AIzaSyC-9yddncWkI1Xn6HVzZWyC0a4GEanElqY',
        authDomain: 'hc-hcqa.firebaseapp.com',
        projectId: 'hc-hcqa',
        storageBucket: 'hc-hcqa.appspot.com',
        messagingSenderId: '933537957428',
        appId: '1:933537957428:web:a20d218d3be94c3efa2c61',
        measurementId: 'G-EJ9B0BGZMR',
    },
    vmpAPI: {
        endPoint: 'https://node-hcqa.travizory.ch',
        apiKey: 'b6f62f8c-8b83-4289-8271-0c50fade781c',
    },
    clientIds: {
        google: '933537957428-j315u6874sscl9flmt3ojf3mjhaj6omd.apps.googleusercontent.com',
    },
    sessionTokenExtension: true,
    professionOptions: [],
    collapseSidebar: ['super_admin', 'establishment_admin'],
    landingUrl: 'https://hcqa.tvalidator.app/',
    randomizeLoginBackground: false,
    authProviders: {
        google: false,
    },
    reportsUrl: 'https://qa-kibana-hc.travizory.ch',
    clickToCall: false,
    showNotifications: false,
    supportOfflineMode: false,
    hidePages: ['nationalityRequirement'],
    defaultLanguages: ['English'],
    getCurrentLocation: false,
};
